@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;



input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg);
	color: white;
	opacity: 0;
	height: 16px;
	width: 16px;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
	opacity: 1;
}


.inputBase {
	@apply rounded-lg border border-gray-200 px-4 py-2.5 text-sm transition-colors focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500/20;
}

.buttonBase {
	@apply rounded-lg px-4 py-2.5 text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500/20;
}

/* .data-row {
	@apply grid grid-cols-[1fr, auto] gap-2 border-b border-gray-100 py-3 text-sm;
} */

.data-label {
	@apply text-gray-500;
}

.data-value {
	@apply font-medium text-gray-900;
}

.card-hover {
	@apply transition-all duration-300 hover:shadow-lg hover:-translate-y-1;
}

.modal-overlay {
	@apply fixed inset-0 bg-gray-100 bg-opacity-50 flex items-center justify-center z-50;
}

.modal-content {
	@apply bg-white rounded-2xl p-6 max-w-2xl w-full mx-4 max-h-[80vh] overflow-y-auto;
}